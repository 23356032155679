import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-art',
  templateUrl: './ai-art.component.html',
  styleUrls: ['./ai-art.component.less']
})
export class AiArtComponent {

}

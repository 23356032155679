<div class="pagecontent">
    <p>Every once in a blue moon inspiration stricks me, and, drawing from either an experience or a conversation, I decide that I would like to explore and comment on a topic in writing. The fruit of such efforts I am going to place here for others to read. I am not going to make any promises about posting timelines, as I am certain I would not be able to keep them. Final an important disclosure that all the comments/thoughts present here are my solely my own and have no direct connection to any and all organizations I may be a part of. I'm also not going to enable any method for public comments, but if one would like to further discuss a topic with me please consider sending an email to blog@williamcwhitlow.com</p>

    <div class="workscontent">
            <mat-accordion>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>What does the Transcendental Debate about Beauty really concern?</mat-panel-title>
                            <mat-panel-description>
                                My exploration and attempt to explain what is at stake regarding this particular debate.
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <h2>Introduction</h2>
                            <p>
                                Unless one is well versed in metaphysical philosophy one will be surprised to learn how much of a heated debate “beauty as a transcendental” is. Also, though unless one is well versed in metaphysical philosophy one will be at least partially confused about what “beauty as a transcendental” means. This is a topic that I have spent a fair amount of time considering and an idea that was significantly present in the background of my master’s thesis for philosophy. Now a Catholic seminary is one of the unique environments where you will find a large population of people who are well-versed in metaphysical philosophy, usually from a Thomistic perspective (at least at my seminary that is the prevailing viewpoint). I was at a social (of all places) where one of my peers proposed this question out of an honest desire to gain a deeper understanding of the topic. What followed was a heated debate for about 15-20 minutes of which I agreed that both sides of the debate were correct in the reasoning and argumentation, and thus were validly winning the debate. If that seems perplexing to you, stick around and I’ll explain how both sides of a debate can be arguing for the same truth.
                            </p>
                        <h2>The Debate/Matured Viewpoint</h2>
                            <p>
                                TLDR: Some people think beauty is a transcendental because beauty is co-extensive with being. Others, while agreeing that beauty is co-extensive with being, think that transcendental implies a more explicit and unique aspect of being.
                            </p>
                            <p>
                                WORK ON CITATIONS AND BRING THIS OVER
                            </p>
                    </mat-expansion-panel>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <i>De Pulchritudine</i>
                        </mat-panel-title>
                        <mat-panel-description>
                            Master's Thesis for Degree in Philosophical Studies
                        </mat-panel-description>
                    </mat-expansion-panel-header>
                    <object data="../../assets/documents/WWhitlow_De_Pulchritudine.pdf" type="application/pdf" width="100%" height="500px">
                        Sorry, your browser does not support embedded pdfs.
                    </object>
                    <a href="../../assets/documents/WWhitlow_De_Pulchritudine.pdf">Click here to download the file.</a>
                </mat-expansion-panel>
            </mat-accordion>

    </div>
</div>


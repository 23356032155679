import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conjugations-latin',
  templateUrl: './conjugations-latin.component.html',
  styleUrls: ['./conjugations-latin.component.less']
})
export class ConjugationsLatinComponent implements OnInit {

  D1 = [["terra", "terrae", "terrae", "terram", "terra"],["terrae", "terrarum", "terris", "terras", "terris"]];
  D2 = [["servus", "servi", "servo", "servum", "servo"],["servi","servorum","servis","servos", "servis"]]
  D2_n = [["verbum","verbi","verbo","verbum","verbo"],["verba","verborum","verbis","verba","verbis"]]
  D3 = [["pater","patris","patri","patrem","patre"],["patres","patrum","patribus","patres","patribus"]]
  D3_n = [["nomen","nominis","nomini","nomen","nomine"],["nomina","nominum","nominibus","nomina","nominibus"]]
  D4 = [["spiritus","spiritus","spiritui","spiritum","spiritu"],["spiritus","spirituum","spiritibus","spiritus","spiritibus"]]
  D4_n = [["cornu","cornus","cornu","cornu","cornu"],["cornua","cornuum","cornibus","cornua","cornibus"]]
  D5 = [["dies","diei","diei","diem","die"],["dies","dierum","diebus","dies","diebus"]]

  constructor() { }

  checkAnswers(conj: string) {
  	if (conj === "D1") {
	   var D1_00 = this.D1[0][0] ? console.log(true):console.log(false); 
	}
	console.log(D1_00)
  }

  ngOnInit(): void {

  }

}

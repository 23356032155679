<div class="pagecontent">
        <h1>Who Am I?</h1>
        <h2>Childhood</h2>
        <p>It's interesting how this question can seem both very simple to answer and very difficult. The simpliest way to answer this question is to begin sequentially. Yet, even this is a little inadequate, because a truly sequential telling of a life fails to capture the importance and aspect of parents in one's life. So perhaps a brief biography of my parents may be a better starting point. Beginning with my mother, who was from Long Island, created an interesting cultural disonance in my upbringing. Hence, although I am from LaGrange, Ga many people who know the town of LaGrange are surprised when I tell them that I am from LaGrange. I believe that the importance of this is that it developed a certain universal attitude in myself, wherein I neither identify fully as southern nor fully as northern. This detail will be a motiff of who I am. My father on the other hand is from LaGrange, Ga, grew up on a farm, and is someone who knows the town and community well. My parents met in Columbus, Ga where they were working at a Veterinary Clinic. My father having been encouraged by his father to become a doctor settled on Veterinary Medicine. My mother had desired to become a Veterinarian but was not accepted into a Veterinary program. From these foundations arose me. Catholicism was the family religion as my father converted to Catholicism shortly before marrying my mother.</p>
        <p>As such, LaGrange is a community I might best describe as my technical hometown for my entire life, and still is. One challenge of being the son of Veterinary in a smaller town that your father was also born and raised in, is that everyone knows you even though you may not know them. LaGrange is honestly not a town that I am overly comfortable in. My mother did not adapt much to the town and I went to the small private school from Kindergarden through High School. Thus, although there were only 16 people in my graduating class I have not really kept up with any of them. It was not until I left for college that I really felt like I had found the community I could connect with. With one noteable exception. Youth retreats during the summer were tremendous moments filled with grace. It was during one of these retreats that I first had the inclination that God may be calling me to the priesthood. A common motiff amongst men who discern the Catholic priesthood is hearing the call and then doubting/questioning the call and so they try something else first. It's fair to say that I fall into that motiff. Thus although I identify the initial conviction and sensation of a call as something that occured when I was 15 years old by the time I was 18 I was no longer prepared to go to seminary although I thought the call could still be present. Now, being the oldest of three children with a seven year gap between me and my siblings (who are a set of boy/girl twins), meant that I was expected to help with and handle the technological needs of the family. My natural disposition was always to try and be helpful, a disposition that resulted in my being burned a couple of times growing up. Yet, the importance of all this is it explains how I transitioned from plans going to college to prepare for Med School, to studying Computer Science, a true love of mine as this website is at least partially indicative of.that reality.</p>
        <p>One aspect that is indicative of the encounter I had with the Eucharist when I was 15 years old was that from that moment on, I have understood and been faithful to Sunday Mass. This was not always a staple in my family although catechesis was. This was because we would always be working in the Vet Clinic. Really a large part of my childhood was spent in the Vet clinic, which is why emergency type situations are not terrifying for me. I have been present and working in these situations for a tremendously long time.</p>
        <h2>College</h2>
        <p>The start of college is almost always an important moment in a story as it is the beginning of an independence that was hither to unknown. My initial experience of college was a little hit or miss, I tried to join several clubs without much success, I made the weakest attempt at rushing ever, but most significantly from that I discovered the Georgia Tech Catholic Center. It would take me about a year, but I would eventually find this to be my home and main area that I would grow into who I am. Initially, I was only going there in order to satisfy my Sunday obligation, but a chance moment lead to me siging up for a weekly Holy Hour, which is a great opportunity and source of grace. That hour kept me more involved in the GTCC and enabled me to develop a relationship with a mentor. Coincidentally this mentor was ordained to the priesthood for the Archdiocese of Atlanta a little over a week ago. This is the community where I made most of my friendships because the amount of people studying in my major was astronomically large (200-300 students in senior level courses), the one exception of this was for my random roommate from freshman year (whom I have maintained a close friendship throughout college and beyond). All that to say the GTCC was my primary source of community throughout college. It was also the place where my vocation would eventually be reinflammed.</p>
        <h2>Internships</h2>
        <p>My first experience working in Computer Science was a co-op at GTRI in Atlanta which was a great experience being located on Georgia Tech's campus.</p>
        <h3>New York</h3>
        <p>My time in New York was probably the quintessential moment of a bildungsroman. It was a tremendous time of exploration, learning a new environment, making new friends, and experiencing financial tech. Financial tech was the low point of that because it was not a particularly rewarding work. The experience of New York was a great opportunity to develop and solidify my own cultural roots. It can be interesting given the cultural influences of religion. Catholicism was traditionally more prevelant in the Northeastern region of the United States. As such, a large portion of the Catholics within the Archdiocese of Atlanta have a connection to the Northeastern section of the country. New York was wonderful in that the experience itself was so culturally different. The reality of New York is that it is simply a mass of humanity and so the rush and timing is simply a by-product of that.</p>
        <h3>Boston</h3>
        <p>Boston could not be further from New York if it was on the opposite side of the planet. Whereas in NYC moving past people was not personal, in Boston it was. I found myself in Boston stranded in the suburbs without a car. It was a miserable experience that was starting to transform me into an angry Bostonian. A personality that is about as antithetical to my actual personality as possible. The fruit of Boston though was two-fold. One I learned the importance of Eucharist adoration and time in solitude, as I would regularly spend an hour walking to go prayer a Holy Hour once a week (because it was the only thing keeping me sane). The second aspect was I learned Atlanta was home. There is an intangible reality to home in that regard. It was expressed in the immense tearful joy I experienced when my plan touched down. It's found in the fond recognition I feel when I round a bend and see the Atlanta skyline on the interestate. There is something about this city that, although I feel like I would never have personally chosen it to be home, it it is home. Even though I don't think I could truly call myself a Georgian through and through, I do recognize that these are the people I know. I know the culture, the references, the joys, the heartaches, and that more than anything is the aspect of home that lead me to discerning priesthood for the Archdiocese of Atlanta.</p>
        <h2>Seminary</h2>
        <p>As such, by the time I entered my last year of college I knew that I needed to apply for Seminary. However, I was not going to put all my eggs in one basket so I also applied to GTRI and had a job offer from them, but I continued applying for seminary. The stress of this was immense and so I had a thought come to my mind of "God will provide" and my personality at the time was to wonder what that was in Latin, <em>Deus Providebit</em> (btw), the significance of this is that it also happens to be the motto of Notre Dame Seminary in New Orleans. Now as a seminarian for the Archdiocese of Atlanta, I did not have a choice about what Seminary I was going to go to but I had somehow personally adopted a personal motto that was the exact same as the seminary that the Archdiocese of Atlanta chose for me. Now this is a quote from the Bible that comes from Gen 22:8, it is within the narrative of the binding of Isaac. All of which has offered some really fruitful prayer on the power and providence of God.</p>
        <h3>Philosophy</h3>
        <p>Now my GT personality continued into seminary and I continued striving academically. I pushed myself not to take the simple Bachelor's path for Philosophy but instead chose to focus on the more intensive path of persuing an M.A. This is a great moment of God writing straight with crooked lines. It was pride that led me to this goal, but it was grace that brought it to completion. My thesis was deeply metaphysical which makes it difficult to explain succintly without utilizing a wide variety of very technical jargon, but essentially it dealt with look at the many aspects of being and identifying the ways and manner that beauty is present in every single one. Thus the notion of what makes a substance beautiful could be defined, and every substance is in fact beautiful to the one who pauses long enough to become aware of it.</p>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <i>De Pulchritudine</i>
                </mat-panel-title>
            <mat-panel-description>
                Master's Thesis for Degree in Philosophical Studies
            </mat-panel-description>
        </mat-expansion-panel-header>
            <object data="../../assets/documents/WWhitlow_De_Pulchritudine.pdf" type="application/pdf" width="100%" height="500px">
                Sorry, your browser does not support embedded pdfs.
            </object>
            <a href="../../assets/documents/WWhitlow_De_Pulchritudine.pdf">Click here to download the file.</a>
        </mat-expansion-panel>
    </mat-accordion>
        <h3>Guatemala</h3>
        <p>Upon finishing Philosophy I went to Guatemala to immerse myself in Spanish for 2 months which has been a life changing moment.</p>
        <h3>Acompaño</h3>
        <p>Of all my life experiences the encounter with Christ in the Eucharist and this mission trip may be my two loves. It was simply such a life changing trip and opened my heart to so many graces that I was not prepared to receive. I returned as part of the leadership team and it has been the same. It's funny because mission work is the most rewarding and promissing thing I do, in part because it is an escape and in part because it is the only time I permit myself to let go of my own desires, plans, and expectations.</p>
        <p>This trip is also the perfect combination of all my life gifts as I strive to truly be a Renaissance man.</p>
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Acompaño Mission Trip Film
                </mat-panel-title>
                <mat-panel-description>
                    Short Film composed of footage from the Notre Dame Seminary 2022 Trip
                </mat-panel-description>
            </mat-expansion-panel-header>
            <video controls controlslist="nodownload" style="width: 100%">
                <source src="https://williamcwhitlow.com/mov/Acompano2023.mp4" type="video/mp4" />
                <p>
                    Your browser doesn't support HTML video. Here is a <a href="../../movies/Pater Organization Acompaño 2022 With Logo.mp4">link</a> to the video instead.
                </p>
            </video>
        </mat-expansion-panel>
    </mat-accordion>
    <h2>CPE</h2>
    <p>It's been a longish road here. As of this year I cannot eat a wide variety of foods because of recently developed allergy situations. Something that may have been the most challenging aspect of my life that I have ever endured, and that's not to say that my life has been completely free of challenges up to this moment...</p>
</div>

<div class="pagecontent">
<label>1st Declension: terra, terrae, <i>f.</i></label>
<br>
<label>Nominative</label>
<input type="text" class="pagecontent" #D1_00 id="D1_00">
<input type="text" class="pagecontent" #D1_10 id="D1_10">
<br>
<label>Genitive</label>
<input type="text" #D1_01 id="D1_01">
<input type="text" #D1_11 id="D1_11">
<br>
<label>Dative</label>
<input type="text" #D1_02>
<input type="text" #D1_12>
<br>
<label>Accusative</label>
<input type="text" #D1_03>
<input type="text" #D1_13>
<br>
<label>Ablative</label>
<input type="text" #D1_04>
<input type="text" #D1_14>
<br>
<button (click)="checkAnswers('D1')">Check</button>
<button>Reset</button>
<!--
<br>
<label>2nd Declension: servus, servi <i>m.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D2_00">
<input type="text" id="D2_10">
<br>
<label>Genitive</label>
<input type="text" id="D2_01">
<input type="text" id="D2_11">
<br>
<label>Dative</label>
<input type="text" id="D2_02">
<input type="text" id="D2_12">
<br>
<label>Accusative</label>
<input type="text" id="D2_03">
<input type="text" id="D2_13">
<br>
<label>Ablative</label>
<input type="text" id="D2_04">
<input type="text" id="D2_14">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>2nd Declension: verbum, verbi <i>n.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D2_00_n">
<input type="text" id="D2_10_n">
<br>
<label>Genitive</label>
<input type="text" id="D2_01_n">
<input type="text" id="D2_11_n">
<br>
<label>Dative</label>
<input type="text" id="D2_02_n">
<input type="text" id="D2_12_n">
<br>
<label>Accusative</label>
<input type="text" id="D2_03_n">
<input type="text" id="D2_13_n">
<br>
<label>Ablative</label>
<input type="text" id="D2_04_n">
<input type="text" id="D2_14_n">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>3rd Declension: pater, patris <i>m.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D3_00">
<input type="text" id="D3_10">
<br>
<label>Genitive</label>
<input type="text" id="D3_01">
<input type="text" id="D3_11">
<br>
<label>Dative</label>
<input type="text" id="D3_02">
<input type="text" id="D3_12">
<br>
<label>Accusative</label>
<input type="text" id="D3_03">
<input type="text" id="D3_13">
<br>
<label>Ablative</label>
<input type="text" id="D3_04">
<input type="text" id="D3_14">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>3rd Declension: nomen, nominis <i>n.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D3_00_n">
<input type="text" id="D3_10_n">
<br>
<label>Genitive</label>
<input type="text" id="D3_01_n">
<input type="text" id="D3_11_n">
<br>
<label>Dative</label>
<input type="text" id="D3_02_n">
<input type="text" id="D3_12_n">
<br>
<label>Accusative</label>
<input type="text" id="D3_03_n">
<input type="text" id="D3_13_n">
<br>
<label>Ablative</label>
<input type="text" id="D3_04_n">
<input type="text" id="D3_14_n">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>4th Declension: spiritus, spiritus <i>m.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D4_00">
<input type="text" id="D4_10">
<br>
<label>Genitive</label>
<input type="text" id="D4_01">
<input type="text" id="D4_11">
<br>
<label>Dative</label>
<input type="text" id="D4_02">
<input type="text" id="D4_12">
<br>
<label>Accusative</label>
<input type="text" id="D4_03">
<input type="text" id="D4_13">
<br>
<label>Ablative</label>
<input type="text" id="D4_04">
<input type="text" id="D4_14">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>4th Declension: cornu, cornus <i>n.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D4_00_n">
<input type="text" id="D4_10_n">
<br>
<label>Genitive</label>
<input type="text" id="D4_01_n">
<input type="text" id="D4_11_n">
<br>
<label>Dative</label>
<input type="text" id="D4_02_n">
<input type="text" id="D4_12_n">
<br>
<label>Accusative</label>
<input type="text" id="D4_03_n">
<input type="text" id="D4_13_n">
<br>
<label>Ablative</label>
<input type="text" id="D4_04_n">
<input type="text" id="D4_14_n">
<br>
<button>Check</button>
<button>Reset</button>

<br>
<label>5th Declension: dies, diei <i>m.</i></label>
<br>
<label>Nominative</label>
<input type="text" id="D5_00">
<input type="text" id="D5_10">
<br>
<label>Genitive</label>
<input type="text" id="D5_01">
<input type="text" id="D5_11">
<br>
<label>Dative</label>
<input type="text" id="D5_02">
<input type="text" id="D5_12">
<br>
<label>Accusative</label>
<input type="text" id="D5_03">
<input type="text" id="D5_13">
<br>
<label>Ablative</label>
<input type="text" id="D5_04">
<input type="text" id="D5_14">
<br>
<button>Check</button>
<button>Reset</button>
-->
</div>

<div class="container">
<img src="{{path}}" class="banner">
</div>
<div class="navbar">
	<ul id="navbuttons">
		<li *ngFor="let item of selections">
			<a [title]="item.name" routerLink="{{ item.url }}" routerLinkActive="{{ item.status }}">{{ item.name }}</a>
		</li>
	</ul>
</div>

<div class="workscontent">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Devotional Images
                </mat-panel-title>
                <mat-panel-description>
                    Images of the Saints and Religious Feasts
                </mat-panel-description>
            </mat-expansion-panel-header>
            DECIDE HOW TO PUT IMAGES HERE
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Sticker Designs
                </mat-panel-title>
            <mat-panel-description>
                    Stickers that I have designed. 
            </mat-panel-description>
        </mat-expansion-panel-header>
            DECIDE HOW TO PUT IMAGES HERE
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Wallpapers
                </mat-panel-title>
            <mat-panel-description>
                    Phone/Tablet Wallpapers
            </mat-panel-description>
        </mat-expansion-panel-header>
            DECIDE HOW TO PUT IMAGES HERE
        </mat-expansion-panel>
    </mat-accordion>

</div>

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { DemoComponent } from './demo/demo.component';
import { WorksComponent } from './works/works.component';
import { AiArtComponent } from './ai-art/ai-art.component';
import { WhoAmIComponent } from './who-am-i/who-am-i.component';
// import { ConjugationsLatinComponent } from './conjugations-latin/conjugations-latin.component';

const routes: Routes = [
        { path: '', component: HomeComponent },
        { path: 'home', component: HomeComponent },
        { path: 'blog', component: BlogComponent },
        { path: 'about', component: AboutComponent },
        { path: 'who-am-i', component: WhoAmIComponent },
        // { path: 'demo', component: DemoComponent },
        { path: 'works', component: WorksComponent },
        { path: 'ai-art', component: AiArtComponent },
        // { path: 'conjugations-latin', component: ConjugationsLatinComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

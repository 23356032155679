import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.less']
})
export class NavbarComponent implements OnInit {
        selections = [
                {
                    id: 1,
                    name: 'Home',
                    title: 'Home',
                    url: '/home',
                    status: 'active'
                },
                {
                    id: 2,
                    name: 'About',
                    title: 'About',
                    url: '/about',
                    status: 'active'
                },
                {
                    id: 3,
                    name: 'Blog',
                    title: 'Blog',
                    url: '/blog',
                    status: 'active'
                },
                {
                    id: 4,
                    name: 'Demo',
                    title: 'Demo',
                    url: '/demo',
                    status: 'deactive'
                },
                // {
                //     id: 7,
                //     name: 'Who Am I?',
                //     title: 'Who Am I?',
                //     url: '/who-am-i',
                //     status: 'active'
                // },
                {
                    id: 6,
                    name: 'AI Art',
                    url: '/ai-art',
                    status: 'active'
                },
                {
                    id: 5,
                    name: 'Works',
                    title: 'Works',
                    url: '/works',
                    status: 'active'
                }
        ];

        path = '../../../assets/images/WebsiteBanner.png';

  constructor() { }

  ngOnInit(): void {

  }

}

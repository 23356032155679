<div class="workscontent">
    <mat-accordion>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Acompaño Mission Trip Film
                </mat-panel-title>
                <mat-panel-description>
                    Short Film composed of footage from the Notre Dame Seminary 2022 Trip
                </mat-panel-description>
            </mat-expansion-panel-header>
            <video controls controlslist="nodownload" style="width: 100%">
                <source src="https://williamcwhitlow.com/mov/PaterAcompanoMovie.mp4" type="video/mp4" />
                <p>
                    Your browser doesn't support HTML video. Here is a <a href="../../movies/Pater Organization Acompaño 2022 With Logo.mp4">link</a> to the video instead.
                </p>
            </video>
        </mat-expansion-panel>
        <mat-expansion-panel>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <i>De Pulchritudine</i>
                </mat-panel-title>
            <mat-panel-description>
                Master's Thesis for Degree in Philosophical Studies
            </mat-panel-description>
        </mat-expansion-panel-header>
            <object data="../../assets/documents/WWhitlow_De_Pulchritudine.pdf" type="application/pdf" width="100%" height="500px">
                Sorry, your browser does not support embedded pdfs.
            </object>
            <a href="../../assets/documents/WWhitlow_De_Pulchritudine.pdf">Click here to download the file.</a>
        </mat-expansion-panel>
    </mat-accordion>

</div>

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MatExpansionModule } from '@angular/material/expansion';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { BlogComponent } from './blog/blog.component';
import { DemoComponent } from './demo/demo.component';
import { AboutComponent } from './about/about.component';
import { ConjugationsLatinComponent } from './conjugations-latin/conjugations-latin.component';
import { WorksComponent } from './works/works.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AiArtComponent } from './ai-art/ai-art.component';
import { WhoAmIComponent } from './who-am-i/who-am-i.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    BlogComponent,
    DemoComponent,
    AboutComponent,
    ConjugationsLatinComponent,
    WorksComponent,
    AiArtComponent,
    WhoAmIComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
